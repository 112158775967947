import { doActionApi } from '@/api/user';
import type { SecondConfirmArgsType } from '@/types/common';
import type { OBJECT_TYPE } from '@/types/constant';
import { DIRECTORY_ID_KEY, USER_ACTION } from '@/types/constant';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { Modal, message } from 'ant-design-vue';
import { v4 } from 'uuid';

// export * from './hooks';
export * from './sensors';

const secondConfirmDefault: any = {
  deleteMaking: {
    title: 'Are you sure you want to delete this project?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  removeMaking: {
    title: 'Are you sure you want to remove this project?',
    successMsg: 'Removed successfully',
    errorMsg: 'Sorry, something went wrong, please try again later!',
    actionType: USER_ACTION.FAVORITE,
  },
  removeCollection: {
    title: 'Are you sure you want to remove this collection?',
    successMsg: 'Removed successfully',
    errorMsg: 'Sorry, something went wrong, please try again later!',
    actionType: USER_ACTION.FAVORITE,
  },
  unfollowQuestion: {
    title: 'Are you sure you want to unfollow this question?',
    successMsg: 'Unfollowed successfully',
    errorMsg: 'Sorry, something went wrong, please try again later!',
    actionType: USER_ACTION.FOLLOW,
  },
  unfollowDiscussion: {
    title: 'Are you sure you want to remove this discussion?',
    successMsg: 'Unfollowed successfully',
    errorMsg: 'Sorry, something went wrong, please try again later!',
    actionType: USER_ACTION.FOLLOW,
  },
  deleteQuestion: {
    title: 'Are you sure you want to delete this question?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  deleteDiscussion: {
    title: 'Are you sure you want to delete this discussion?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  deleteAnswer: {
    title: 'Are you sure you want to delete this answer?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  deleteReply: {
    title: 'Are you sure you want to delete this reply?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  deleteComment: {
    title: 'Are you sure you want to delete this comment?',
    successMsg: 'Deleted successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
  removeFollower: {
    title: 'Are you sure you want to remove the user from your followers？',
    // content: h('div', {}, [
    //   h('div', 'They will be removed from your followers.'),
    //   h('div', 'They can follow you again in the future. '),
    // ]),
    successMsg: 'Removed successfully',
    errorMsg: 'Operation failed, please try again later!',
  },
};

// 获取格式化图片url
export const getOssProcessedImage = (src: string | undefined, resize?: string, format?: string) => {
  if (!src) {
    return '';
  }
  if (src?.indexOf('https') === -1) {
    return src;
  }
  let processedSrc = src;
  if (resize || format) {
    processedSrc += '?x-oss-process=image';
    if (resize) {
      processedSrc += `/resize,${resize},limit_0`;
    }
    if (format) {
      processedSrc += `/interlace,1/format,${format}`;
    }
  }

  return processedSrc;
};

const handleSensorsAction = (actionType: string, objectType: string, item: any, revoke: boolean, entry: string) => {
  const { sensorCollectContentEvent, sensorContentLikeEvent, sensorFollowEvent } = useSensors();
  let service_type;
  if (objectType === 'making') {
    service_type = item.type;
  } else {
    service_type = objectType;
  }
  if (['comment', 'answer'].includes('service_type')) {
    return;
  }
  if (actionType === 'favorite') {
    sensorCollectContentEvent({
      content_id: item.id,
      entry: entry,
      content_name: item.title,
      action_revoke: revoke,
      service_type,
      author_name: item.createdByName,
    });
  }
  if (actionType === 'like') {
    sensorContentLikeEvent({
      content_id: item.id,
      entry: entry,
      content_name: item.title,
      action_revoke: !revoke,
      service_type,
      author_name: item.createdByName,
    });
  }
  if (actionType === 'follow') {
    sensorFollowEvent({
      content_id: item.id,
      content_name: item.title,
      action_revoke: !revoke,
      service_type,
      author_name: item.createdByName,
    });
  }
};
const actionStack: any = [];
const handleIsContiue = (objectType: OBJECT_TYPE, item: any, actionType: string, revoke: boolean) => {
  const str = `${actionType}-${objectType}-${item.id}-${item.type}-${revoke}`;
  if (actionStack.length) {
    if (actionStack.includes(str)) {
      return false;
    }
    actionStack.push(str);
    return true;
  }
  actionStack.push(str);
  return true;
};
const removeStack = (objectType: OBJECT_TYPE, item: any, actionType: string, revoke: boolean) => {
  const str = `${actionType}-${objectType}-${item.id}-${item.type}-${revoke}`;
  const index = actionStack.findIndex((item: string) => {
    return item === str;
  });
  if (index > -1) {
    actionStack.splice(index, 1);
  }
};
// 处理点击操作按钮
export const handleToggleAction = async (objectType: OBJECT_TYPE, item: any, actionType: string, entry?: string, hasCookie?: boolean) => {
  const revoke = item.userAction[actionType] > 0;

  const isGo = handleIsContiue(objectType, item, actionType, revoke);
  // note: 增加 hasCookie 是否登录判断，未登录的一路 弹窗提示
  if (!isGo && hasCookie) {
    return;
  }

  try {
    handleSensorsAction(actionType, objectType, item, revoke, entry as string);
    const { data } = await doActionApi(actionType, {
      objectType,
      objectId: item.id,
      contentType: item.type,
      revoke,
    });
    if (data.value?.code === 0) {
      if (revoke) {
        item.userAction[actionType]--;
        item.actionSum[actionType]--;
      } else {
        item.userAction[actionType]++;
        item.actionSum[actionType]++;
      }
      if (actionStack.length) {
        removeStack(objectType, item, actionType, revoke);
      }
    }
  } catch (error) {
    message.error('Operation failed, retry later!');
    console.log(error);
  }
};

// 处理二次确认弹窗
export const handleSecondConfirm = (args: SecondConfirmArgsType) => {
  const { apiFun, type, apiParams, okText = 'OK', width = 550, cb } = args;
  const config = secondConfirmDefault[type];
  Modal.confirm({
    title: config.title,
    content: config.content || '',
    okText,
    width,
    closable: true,
    icon: () => {
      return h(ExclamationCircleFilled);
    },
    onOk: async () => {
      try {
        const { data } = await (config.actionType ? apiFun(config.actionType, apiParams) : apiFun(apiParams));
        // const { code } = res;
        if (data.value?.code === 0) {
          message.success(config.successMsg, 2);
          typeof cb === 'function' && cb();
        }
      } catch (error) {
        message.error(config.errorMsg);
        console.log(error);
      }
    },
  });
};

// 滚动加载表格数据去重
export const handleDataDeduplication = (originList: any, newList: any) => {
  const arr: any[] = [];
  const origin = newList ? newList : [];
  // 前一页需要比对的数组起始
  // const preStart = originList.length - newList.length;
  // 前一页需要比对的id
  // const prePageIds: number[] = originList
  //   .slice(preStart, originList.length)
  //   .map((item: any) => item.id);

  // 对比整个列表的id
  const pageIds: number[] = originList.map((item: any) => item.id);

  // 比对，若无重复则加入新数组
  origin.forEach((item: any) => {
    if (!pageIds.includes(item.id)) {
      arr.push(item);
    }
  });

  return arr;
};

// 将id占位符替换成随机id
export const replaceLevelWithUniqueUUID = (str: string) => {
  const regx = new RegExp(DIRECTORY_ID_KEY, 'g');
  return str.replace(regx, () => {
    return v4();
  });
};

// 文件大小转换显示
export const getFormatFileSize = (size: number, pointLength?: number, units?: string[]) => {
  let unit;
  units = units || ['B', 'KB', 'MB', 'GB', 'TB'];
  while ((unit = units.shift()) && size > 1024) {
    size = size / 1024;
  }
  return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + (unit as string);
};

// 文件名超长中间用省略号处理
export const truncateFileName = (fileName: string, maxLength: number): string => {
  if (fileName.length <= maxLength) {
    return fileName;
  } else {
    const ellipsis = '...';
    const fileNameWithoutExt = fileName.slice(0, fileName.lastIndexOf('.'));
    const ext = fileName.slice(fileName.lastIndexOf('.'));
    const truncatedLength = maxLength - ellipsis.length - ext.length;
    const truncatedName = fileNameWithoutExt.slice(0, truncatedLength);
    return truncatedName + ellipsis + ext;
  }
};

/**
 * 判断是否为 format 格式的图片
 * @param url 图片地址
 * @param format 图片格式
 * @returns 是否为 format 格式的图片
 * @example
 * checkImg('https://example.com/1.gif', 'gif') // true
 */
export const checkImg = (url: string, format: string) => {
  if (!url || !format) {
    console.warn('url or format is empty');
    return false;
  }
  return url.toLowerCase().endsWith(format);
};

/**
 * 根据链接下载文件
 * @param {string} url
 * @param {string} [fname]
 */
export const fileDownload = (url: string, fname?: string) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('download', fname || '');
  link.setAttribute('href', url);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * 创建a标签，用于打开链接，可以选择打开方式
 */
export function createAlink(url: string, target: string = '_self') {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);
  a.setAttribute('target', target);
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * 检查是否处于活动流程中,活动流程不能投付费稿,存量的旧活动地址不处理,地址栏中包含:
 * /activity/post/xx;
 * /activity/temp-page/xx;
 * /activity/success
 */
export function checkIsAtivity() {
  if (import.meta.server) {
    return false;
  }
  const regx = /\/activity\/(post|temp-page|success)/;
  return regx.test(globalThis.location.pathname);
}

<template>
  <div :class="['eclipse-action', `${type}-eclipse-icon`]" class="flex items-center px-0.5 mobile:hidden">
    <a-popover v-if="actions?.length" v-model:open="toggle" overlay-class-name="custom-eclipse-action-popover" :trigger="[trigger]" @click.stop="">
      <template #content>
        <a-button
          v-for="actionType in actions"
          :key="actionType"
          class="eclipse-action-text-btn hover:text-uni-0"
          type="text"
          @click.stop="handleToggleActions(actionType)"
        >
          {{ actionType.replace(actionType[0], actionType[0].toUpperCase()) }}
        </a-button>
      </template>
      <slot name="icon">
        <icon-font class="text-level-1" type="icon-more-fill" @click.prevent="" />
      </slot>
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
type PropsType = {
  actions?: string[]; // 权限列表，例['delete']
  type?: string;
  trigger?: any;
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
};

withDefaults(defineProps<PropsType>(), {
  trigger: 'click',
});
const emits = defineEmits<EmitsType>();
const handleToggleActions = (type: string) => {
  emits('toggleActions', type);
  toggle.value = false;
};
const toggle = ref(false);
</script>

<style lang="less">
.custom-eclipse-action-popover {
  .ant-popover-inner {
    padding: 4px 6px;
  }
}
</style>

<style lang="less" scoped>
.eclipse-action-text-btn {
  display: block;
  width: 100%;
  text-align: center;
}
.block-eclipse-icon {
  background: rgba(33, 33, 33, 0.8);
  border-radius: 2px;
  color: #fff;
}
.text-eclipse-icon {
  border-radius: 2px;
  color: rgb(33, 33, 33);
  // &:hover {
  //   background: rgba(33, 33, 33, 0.08);
  // }
}
</style>

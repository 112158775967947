// 编辑器相关接口
import type { BundleItem, DesignItem } from '@/types/share';
import { Methods, REQUEST_SEVER, request } from '@/utils/request';
import { defineFindApi } from '~/utils/fetch/define-api';

// 获取场景-机型-加工头关联数据
export const getScenarioAssociation = defineFindApi({
  url: '/community/v1/web/making/scenario/association',
  method: HttpMethod.GET,
  respTy: RespTy.Json<{
    scenarios: any;
    machines: any;
    powers: any;
    association: any;
  }>(),
});

// 获取top10标签
export const getTop10Tags = defineFindApi({
  url: '/community/v1/web/making/tag/top',
  method: HttpMethod.GET,
  respTy: RespTy.Json<string[]>(),
});

// 获取我的在售创作列表
export const getMySaleMakings = defineFindApi({
  url: '/community/v1/web/my/sale/makings',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
    type?: string;
  }>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      pageSize: number;
      total: number;
    };
  }>(),
});

// 新建bundle案例
export const createBundleApi = (data: BundleItem) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v2/web/making/bundle',
    method: Methods.POST,
    data,
  });
};

// 修改bundle案例
export const updateBundleApi = (id: string, data: BundleItem) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/bundle/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 新建design案例
export const createDesignApi = (data: DesignItem) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v2/web/making/designs',
    method: Methods.POST,
    data,
  });
};
// 修改design案例
export const updateDesignApi = (id: string, data: DesignItem) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/designs/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 获取AI自动标签
export const getAIAutoTagApi = defineFindApi({
  url: '/community/v1/web/making/auto-tag',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    cover: string;
  }>(),
  respTy: RespTy.Json<{
    title: string;
    podTags: string[];
    tags: string[];
  }>(),
});

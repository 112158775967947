/**
 * 环境判断
 */

/**
 * 是否存在 MeApi (目前在pc端xcs软件内才有值)
 */
// export function isMeApi() {
//   return window?.parent?.MeApi;
// }

/**
 * 在手机/iPad的xcs app为 真 ，在浏览器中为 假 (包括安卓和iOS)
 * 例如：
 * 做链接打开判断，若在 移动端app中，当前页打开链接
 * if (isAppWebkit()) {...}
 */
// export function isAppWebkit() {
//   return (window as any)?.LinkNative || (window as any)?.webkit?.messageHandlers?.LinkNative;
// }

/**
 * 判断当前是否在应用内
 *
 * @returns 返回布尔值，表示是否在应用内
 */
export function isInApp() {
  return isInClient() && (isMeApi() || isAppWebkit() || isElectron());
}
/**
 * 判断当前是否在iPad设备上
 *
 * @returns 返回布尔值，表示是否在iPad设备上
 */
export function isInIPad() {
  return isInClient() && /iPad/.test(navigator?.userAgent);
}
/**
 * 判断当前是否在移动端设备上
 *
 * @returns 返回布尔值，表示是否在移动端设备上
 */
export function isInMobile() {
  return isInClient() && /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
/**
 * 判断当前是否在PC端
 *
 * @returns 返回布尔值，表示是否在PC端
 */
export function isInPC() {
  return isInClient() && !isInIPad() && !isInMobile();
}

/**
 * 判断当前是否在客户端环境中运行
 *
 * @returns 返回布尔值，表示是否在客户端环境中运行
 */
export function isInClient() {
  return typeof window !== 'undefined';
}
/**
 * 判断当前是否在服务器端运行
 *
 * @returns 返回布尔值，表示是否在服务器端运行
 */
export function isInServer() {
  return !isInClient();
}

/**
 * 判断当前是否在PC端应用内
 *
 * @returns 返回布尔值，表示是否在PC端应用内
 */
export function isInPCApp() {
  if (isInClient()) {
    return isInApp() && isInPC();
  } else {
    const nuxtApp = getNuxtApp();
    const headers = nuxtApp?.ssrContext?.event.headers;
    const userAgent = headers?.get('user-agent') ?? '';
    return /Electron/.test(userAgent);
  }
}
/**
 * 判断当前是否在iPad应用内
 *
 * @returns 返回布尔值，表示是否在iPad应用内
 */
export function isInIPadApp() {
  return isInApp() && isInIPad();
}
/**
 * 判断当前是否在移动端应用内
 *
 * @returns 返回布尔值，表示是否在移动端应用内
 */
export function isInMobileApp() {
  return isInApp() && isInMobile();
}

export function isInApple() {
  return isInClient() && /iPad|iPhone|iPod|Mac/i.test(navigator.userAgent);
}

export function isInIPhone() {
  return isInClient() && /iPhone/i.test(navigator.userAgent);
}

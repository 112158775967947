// sensors神策采集插件
// 使用说明
// const { sensorFollowEvent } = useSensors()
// sensorSearchClick(...)
import type {
  SensorCreatorApplyResultType,
  SensorCreatorApplySubmitType,
  SensorCreatorSetpayoutsSubmitType,
  SensorEditorClickType,
  SensorForYouSeeFewerType,
  SensorForYouTagChooseType,
  SensorsAddCommentEventType,
  SensorsAllFilterChoose,
  SensorSaveMakingClickEventType,
  SensorsBannerClickEventType,
  SensorsBillingDetailType,
  SensorsBomExposure,
  SensorsBomShop,
  SensorsButtonClickEventType,
  SensorsCollectContentEventType,
  SensorsCollectionClickEventType,
  SensorsCommentClickEventType,
  SensorsCommentFinishEventType,
  SensorsConfigurationClickEventType,
  SensorsContentClickEventType,
  SensorsContentLikeType,
  SensorsContentMakingType,
  SensorsCreateYoursType,
  SensorsCreatorCenterDashboardClick,
  SensorsCreatorCenterMenuClick,
  SensorsDraftItem,
  SensorsDragFileType,
  SensorsFeedbackClickEventType,
  SensorsFollowEventType,
  SensorsGuidelineClickEventType,
  SensorsHomepagecollectionClickEventType,
  SensorsLibraryConfirmType,
  SensorsLibraryEmbedVideoType,
  SensorsLibraryUploadFileType,
  SensorsMakingOptionClickEventType,
  SensorsPreviewMakingClickEventType,
  SensorsPublishMakingClickEventType,
  SensorsSeeMoreEventType,
  SensorsShareMyMakingClickEventType,
  SensorsSubmitReportClickEventType,
  SensorsTopicShareClick,
  SensorSubscriptionContentDetailClickType,
  SensorSubscriptionFreeTrialClickType,
  SensorSubscriptionFreeTrialPopShowType,
  SensorSubscriptionOrPayOriginalClickType,
  SensorSubscriptionOrPayOriginalPopShowType,
  SensorSubscriptionPersonalCenterClickType,
  SensorSubscriptionPricingClickType,
} from '@/types';
import { SENSORS_EVENT } from '@/types';

export const useSensors = () => {
  const { $sensors: sensors } = useNuxtApp();
  // 采集关注
  const sensorFollowEvent = (data: SensorsFollowEventType) => {
    sensors.track(SENSORS_EVENT.FOLLOW, {
      ...data,
    });
  };
  // 采集内容块点击
  const sensorContentClickEvent = (data: SensorsContentClickEventType) => {
    sensors.track(SENSORS_EVENT.CONTENT_CLICK, {
      ...data,
    });
  };

  // 采集点击addComment
  const sensorAddCommentEvent = (data: SensorsAddCommentEventType) => {
    sensors.track(SENSORS_EVENT.ADD_COMMENT, {
      ...data,
    });
  };
  // 搜索点击
  const sensorSearchClick = (data: { keyword: string; entry: string }) => {
    sensors.track(SENSORS_EVENT.SEARCH_CLICK, {
      ...data,
    });
  };

  // 采集评论结束
  const sensorCommentFinishEvent = (data: SensorsCommentFinishEventType) => {
    sensors.track(SENSORS_EVENT.COMMENT_FINISH, {
      ...data,
    });
  };
  // 返回搜索结果
  const sensorSearchResult = (data: {
    keyword: string;
    has_result: boolean;
    search_result_num: number;
    howtos_num?: number;
    inspiration_num?: number;
  }) => {
    sensors.track(SENSORS_EVENT.SEARCH_RESULT, {
      ...data,
    });
  };

  // 采集收藏操作
  const sensorCollectContentEvent = (data: SensorsCollectContentEventType) => {
    sensors.track(SENSORS_EVENT.COLLECT_CONTENT, {
      ...data,
    });
  };
  // 点击搜索结果
  const sensorSearchResultClick = (data: { keyword: string; content_id: number; content_name: string; source_module: string }) => {
    sensors.track(SENSORS_EVENT.SEARCH_RESULT_CLICK, {
      ...data,
    });
  };

  // 内容详情浏览
  const sensorContentDetailView = (data: {
    content_id: number;
    content_name: string;
    content_tag: string[];
    publish_time: number;
    author_name: string;
    author_id: number;
    service_type: string; // 内容类型: inspiration/howtos/xThings
    content_type: 'sale' | 'free'; // 付费/免费
    identity_pro?: 'Pro' | 'Pro+' | 'Free'; // 身份: Pro/Pro+/Free
  }) => {
    sensors.track(SENSORS_EVENT.CONTENT_DETAIL_VIEW, {
      ...data,
    });
  };

  // 内容详情浏览结束
  const sensorContentDetailFinish = (data: {
    content_id: number;
    content_name: string;
    content_tag: string[];
    publish_time: number;
    author_name: string;
    author_id: number;
    service_type: string;
  }) => {
    sensors.track(SENSORS_EVENT.CONTENT_DETAIL_FINISH, {
      ...data,
    });
  };

  // 采集收藏操作
  const sensorContentLikeEvent = (data: SensorsContentLikeType) => {
    sensors.track(SENSORS_EVENT.CONTENT_LIKE, {
      ...data,
    });
  };

  // 采集分享项目按钮点击
  const sensorShareMyMakingClickEvent = (data: SensorsShareMyMakingClickEventType) => {
    sensors.track(SENSORS_EVENT.SHARE_MY_MAKING_CLICK, { ...data });
  };

  // 采集新创建项目按钮点击
  const sensorsNewProjectClickEvent = (data: { type: string }) => {
    sensors.track(SENSORS_EVENT.NEW_PROJECT_CLICK, { ...data });
  };

  // 采集草稿箱按钮点击
  const sensorsMyDraftClickEvent = () => {
    sensors.track(SENSORS_EVENT.My_DRAFT_CLICK);
  };

  // 采集板块选择点击
  const sensorsMakingOptionClickEvent = (data: SensorsMakingOptionClickEventType) => {
    sensors.track(SENSORS_EVENT.MAKING_OPTION_CLICK, { ...data });
  };

  // 采集保存按钮点击
  const sensorsSaveMakingClickEvent = (data: SensorSaveMakingClickEventType) => {
    sensors.track(SENSORS_EVENT.SAVE_MAKING_CLICK, { ...data });
  };

  // 采集预览按钮点击
  const sensorsPreviewMakingClickEvent = (data: SensorsPreviewMakingClickEventType) => {
    sensors.track(SENSORS_EVENT.PREVIEW_MAKING_CLICK, { ...data });
  };

  // 采集发布按钮点击
  const sensorsPublishMakingClickEvent = (data: SensorsPublishMakingClickEventType) => {
    sensors.track(SENSORS_EVENT.PUBLISH_MAKING_CLICK, { ...data });
  };

  const sensorsContentMaking = (data: SensorsContentMakingType) => {
    sensors.track(SENSORS_EVENT.CONTENT_MAKING, { ...data });
  };

  // 采集banner点击
  const sensorsBannerClickEvent = (data: SensorsBannerClickEventType) => {
    sensors.track(SENSORS_EVENT.BANNER_CLICK, { ...data });
  };

  // 采集媒体库上传文件
  const sensorLibraryUploadFile = (data: SensorsLibraryUploadFileType) => {
    sensors.track(SENSORS_EVENT.LIBRARY_UPLOAD_FILE, { ...data });
  };

  const sensorsLibraryEmbedVideo = (data: SensorsLibraryEmbedVideoType) => {
    sensors.track(SENSORS_EVENT.LIBRARY_EMBED_VIDEO, { ...data });
  };

  const sensorsDragFile = (data: SensorsDragFileType) => {
    sensors.track(SENSORS_EVENT.DRAG_FILE, { ...data });
  };

  const sensorsGuideLineClickEvent = (data: SensorsGuidelineClickEventType) => {
    sensors.track(SENSORS_EVENT.GUIDELINE_CLICK, { ...data });
  };

  const sensorsLibraryConfirm = (data: SensorsLibraryConfirmType) => {
    sensors.track(SENSORS_EVENT.LIBRARY_CONFIRM, { ...data });
  };

  const sensorsRewardItemClick = (data: { content_id: number; content_name: string; login_status: boolean }) => {
    sensors.track(SENSORS_EVENT.REWARD_ITEM_CLICK, { ...data });
  };

  const sensorsShowXccDialog = () => {
    sensors.track(SENSORS_EVENT.SHOW_XCC_DIALOG);
  };

  const sensorsXccDialogClickBind = () => {
    sensors.track(SENSORS_EVENT.XCC_DIALOG_CLICK_BIND);
  };

  const sensorsXccDialogClickFinish = (data: { status: string }) => {
    sensors.track(SENSORS_EVENT.XCC_DIALOG_CLICK_FINISHED, { ...data });
  };

  const sensorsCloseXccDialog = () => {
    sensors.track(SENSORS_EVENT.CLOSE_XCC_DIALOG);
  };

  const sensorsRewardRedeemSuccess = (data: { content_id: number; content_name: string }) => {
    sensors.track(SENSORS_EVENT.REWARD_REDEEM_SUCCESS, { ...data });
  };

  const sensorsShopXTool = () => {
    sensors.track(SENSORS_EVENT.SHOP_XTOOL);
  };

  const sensorsEarnXToolPointsClick = (data: { content_name: string; login_status: boolean }) => {
    sensors.track(SENSORS_EVENT.EARN_XTOOL_POINTS_CLICK, { ...data });
  };

  const sensorsCreateYours = (data: SensorsCreateYoursType) => {
    sensors.track(SENSORS_EVENT.CREATE_YOUTS, { ...data });
  };

  // 采集xcs内详情页付款后弹窗按钮点击
  const sensorsPaymentConfirm = (data: { type: string }) => {
    sensors.track(SENSORS_EVENT.PAYMENT_CONFIRM, { ...data });
  };

  const sensorsDownloadXCS = () => {
    sensors.track(SENSORS_EVENT.DOWNLOAD_XCS);
  };

  const sensorsXartusageClick = () => {
    sensors.track(SENSORS_EVENT.XARTUSAGE_CLICK);
  };

  const sensorsTryClick = () => {
    sensors.track(SENSORS_EVENT.TRY_CLICK);
  };

  const sensorsInstallPop = () => {
    sensors.track(SENSORS_EVENT.INSTALL_POP);
  };
  // 下载成功弹窗
  const sensorsDownpopSuccess = (data: {
    content_name: string; // 内容名称
    content_id: number; // 内容ID
    publish_time: number; // 发布时间
    file_name: string; // 下载文件名称
    entry: string; // 入口
    service_type: string; // 所属内容类型
    content_type: string; // 案例类型
    identity_pro?: 'Pro' | 'Pro+' | 'Free'; // 身份: Pro/Pro+/Free
  }) => {
    sensors.track(SENSORS_EVENT.DOWN_POP_SUCCESS, { ...data });
  };
  const sensorsDownpopLike = (data: { content_name: string; content_id: number; publish_time: number }) => {
    sensors.track(SENSORS_EVENT.DOWN_POP_LIKE, { ...data });
  };
  const sensorsDownpopCollect = (data: { content_name: string; content_id: number; publish_time: number }) => {
    sensors.track(SENSORS_EVENT.DOWN_POP_COLLECT, { ...data });
  };
  const sensorsDownpopShare = (data: { content_name: string; content_id: number; publish_time: number }) => {
    sensors.track(SENSORS_EVENT.DOWN_POP_SHARE, { ...data });
  };
  const sensorsDownpopComment = (data: { content_name: string; content_id: number; publish_time: number }) => {
    sensors.track(SENSORS_EVENT.DOWN_POP_COMMENT, { ...data });
  };

  const sensorsToSupport = () => {
    sensors.track(SENSORS_EVENT.JOIN_SUPPORT_CLICK);
  };

  const sensorsToSupportModal = () => {
    sensors.track(SENSORS_EVENT.ASK_CLICK);
  };
  const sensorsxArtworksClick = () => {
    sensors.track(SENSORS_EVENT.XARTWORKSCLICK);
  };
  const sensorsfeatuedClick = (data: { file_type: string }) => {
    sensors.track(SENSORS_EVENT.FEATURECLICK, { ...data });
  };
  const sensorsentriesClick = (data: { file_type: string }) => {
    sensors.track(SENSORS_EVENT.ENTRIESCLICK, { ...data });
  };
  const sensorsxArtenterNowClick = (data: { entry: string; activity_name?: string }) => {
    sensors.track(SENSORS_EVENT.ENTERNOW, { ...data });
  };
  const sensorsSeemoreClick = (data: { see_type: string }) => {
    sensors.track(SENSORS_EVENT.SEEMORECLICK, { ...data });
  };
  const sensorsxHollaNowClick = (data: { entry: string }) => {
    sensors.track(SENSORS_EVENT.HOLLAENTERNOW, { ...data });
  };
  const sensorsPreviewPage = () => {
    sensors.track(SENSORS_EVENT.PREVIEWPAGE);
  };
  const sensorsxOpenInBrowser = () => {
    sensors.track(SENSORS_EVENT.OPENINBROWSER);
  };
  const sensorsCopyCode = (data: { winning_pop: string; coupon_type: string }) => {
    sensors.track(SENSORS_EVENT.COPYCODE, { ...data });
  };
  const sensorsCheckFile = (data: { winning_pop: string }) => {
    sensors.track(SENSORS_EVENT.CHECKFILE, { ...data });
  };
  const sensorsLottery = () => {
    sensors.track(SENSORS_EVENT.LOTTERY);
  };
  const sensorsShareNow = (data: { task_type: string; share_source: string }) => {
    sensors.track(SENSORS_EVENT.SHARENOW, { ...data });
  };
  const sensorsActivityDownload = (data: { content_name: string; content_id: string; activity_name: string }) => {
    sensors.track(SENSORS_EVENT.ACTIVITYDOWNLOD, { ...data });
  };
  const sensorsReportClick = (data: { keyword: string; module_type: string }) => {
    sensors.track(SENSORS_EVENT.REPORTCLICK, { ...data });
  };
  const sensorsConfirmClick = (data: { search_tag: string[]; design_file: string }) => {
    sensors.track(SENSORS_EVENT.CONFIRMCLICK, { ...data });
  };
  const sensorsRecommendClick = (data: { recommend_type: string; recommend_word: string }) => {
    sensors.track(SENSORS_EVENT.RECOMMENDCLICK, { ...data });
  };
  // addremix 按钮 点击
  const sensorsRemixClick = (data: { remix_text: string }) => {
    sensors.track(SENSORS_EVENT.REMIXCLICK, { ...data });
  };
  // 采集 举报按钮 点击
  const sensorsSubmitReport = (data: SensorsSubmitReportClickEventType) => {
    sensors.track(SENSORS_EVENT.SUBMITREPORT, { ...data });
  };
  // 采集 付费按钮 点击
  const sensorsBillingDetail = (data: SensorsBillingDetailType) => {
    sensors.track(SENSORS_EVENT.BILLLING_DETAIL, { ...data });
  };
  // 采集 进入付费合集 点击
  const sensorsStartShopping = (data: {
    source?: string; // 进入来源
    type?: string; // 类型
  }) => {
    sensors.track(SENSORS_EVENT.START_SHOPPING, { ...data });
  };
  // 设置默认版权
  const sensorsLicenseAgree = (data: { license_content: string }) => {
    sensors.track(SENSORS_EVENT.LICENSEAGREE, { ...data });
  };
  // 采集案例合集点击
  const sensorCollectionClick = (data: SensorsCollectionClickEventType) => {
    sensors.track(SENSORS_EVENT.COLLECTIONCLICK, {
      ...data,
    });
  };
  // 采集首页配置位点击
  const sensorConfigurationClick = (data: SensorsConfigurationClickEventType) => {
    sensors.track(SENSORS_EVENT.CONFIGURATIONCLICK, {
      ...data,
    });
  };
  // 采集按钮点击
  const sensorButtonClick = (data: SensorsButtonClickEventType) => {
    sensors.track(SENSORS_EVENT.BUTTONCLICK, {
      ...data,
    });
  };
  // 采集首页查看更多按钮点击
  const sensorsSeeMore = (data: SensorsSeeMoreEventType) => {
    sensors.track(SENSORS_EVENT.SEEMORE, {
      ...data,
    });
  };
  const sensorsFeedbackClick = (data: SensorsFeedbackClickEventType) => {
    sensors.track(SENSORS_EVENT.FEEDBACK_CLICK, {
      ...data,
    });
  };
  // 采集首页推荐位点击
  const sensorsHomepagecollectionClick = (data: SensorsHomepagecollectionClickEventType) => {
    sensors.track(SENSORS_EVENT.HOMEPAGECOLLECTIONCLICK, {
      ...data,
    });
  };
  // 采集首页for you筛选框点击
  const sensorsFilterClick = (data: {
    block_id: number; // 模块id
    block_name: string; // 模块名称
    filtercontent: string; // 筛选框名称 Assets Type/Assets from
  }) => {
    sensors.track(SENSORS_EVENT.FILTERCLICK, {
      ...data,
    });
  };
  // 采集首页for you筛选框完成选择
  const sensorsFilterFinish = (data: {
    block_id: number; // 模块id
    block_name: string; // 模块名称
    filtercontent: string; // 筛选框名称 Assets Type/Assets from
    element_name: string; // 选择的值 Projects/Design/Template/Premium Assets/Community Shared Assets等
  }) => {
    sensors.track(SENSORS_EVENT.FILTERFINISH, {
      ...data,
    });
  };
  // 采集消息中心tab点击
  const sensorsMessageCenterClick = (data: {
    elementId: string; // 按钮名称
  }) => {
    sensors.track(SENSORS_EVENT.MESSAGE_SCENTER_CLICK, {
      ...data,
    });
  };
  // 采集评论区点击
  const sensorCommentClick = (data: SensorsCommentClickEventType) => {
    sensors.track(SENSORS_EVENT.COMMENT_CLICK, {
      ...data,
    });
  };

  // 草稿箱item点击
  const sensorDraftItemClick = (data: SensorsDraftItem) => {
    sensors.track(SENSORS_EVENT.DRAFT_CLICK, {
      ...data,
    });
  };
  // 付费案例限制弹窗点击
  const sensorPrimeLimitClick = () => {
    sensors.track(SENSORS_EVENT.PRIME_LIMIT_CLICK);
  };
  // 详情页-BoM点击
  const sensorBomSopClick = (data: SensorsBomShop) => {
    sensors.track(SENSORS_EVENT.BOM_SHOP, {
      ...data,
    });
  };
  // 详情页-BoM曝光
  const sensorBomExposure = (data: SensorsBomExposure) => {
    sensors.track(SENSORS_EVENT.BOM_EXPOSURE, {
      ...data,
    });
  };
  // all页面-筛选框选择
  const sensorFulterChoose = (data: SensorsAllFilterChoose) => {
    sensors.track(SENSORS_EVENT.FILTERCHOOSE, {
      ...data,
    });
  };
  const sensorSelectWordClick = (data: SensorsAllFilterChoose) => {
    sensors.track(SENSORS_EVENT.SELECTWORDCLICK, {
      ...data,
    });
  };

  const sensorCreatorCenterEntryClick = () => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_ENTRY_CLICK);
  };
  const sensorCreatorCenterMenuClick = (data: SensorsCreatorCenterMenuClick) => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_MENU_CLICK, {
      ...data,
    });
  };
  const sensorCreatorCenterDashboardClick = (data: SensorsCreatorCenterDashboardClick) => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_DASHBOARD_CLICK, {
      ...data,
    });
  };
  const sensorCreatorOtherPayoutClick = () => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_OTHER_PAYOUT_CLICK);
  };
  const sensorCreatorCenterSelectPayoutClick = (data: { element_name: string }) => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_SELECT_PAYOUT_CLICK, {
      ...data,
    });
  };
  const sensorCreatorCenterOtherPayoutsFormSubmit = (data: { element_name: string; status: number }) => {
    sensors.track(SENSORS_EVENT.CREATOR_CENTER_OTHER_PAYOUTS_FORM_SUBMIT, {
      ...data,
    });
  };

  const sensorTopicShareClick = (data: SensorsTopicShareClick) => {
    sensors.track(SENSORS_EVENT.TOPIC_SHARE_CLICK, {
      ...data,
    });
  };

  // 创作者中心 - 设置收款人-页面曝光
  const sensorCreatorSetpayoutsExposure = () => {
    sensors.track(SENSORS_EVENT.CREATOR_SET_PAYOUTS_FORM_EXPOSURE);
  };

  // 创作者中心 - 设置收款人-表单提交
  const sensorCreatorSetpayoutsSubmit = (data: SensorCreatorSetpayoutsSubmitType) => {
    sensors.track(SENSORS_EVENT.CREATOR_SET_PAYOUTS_FORM_SUBMIT, {
      ...data,
    });
  };

  // 创作者中心 - 申请页曝光
  const sensorCreatorApplyExposure = () => {
    sensors.track(SENSORS_EVENT.CREATOR_APPLY_EXPOSURE);
  };

  // 创作者中心 - 设置收款人-表单提交
  const sensorCreatorApplySubmit = (data: SensorCreatorApplySubmitType) => {
    sensors.track(SENSORS_EVENT.CREATOR_APPLY_SUBMIT, {
      ...data,
    });
  };

  // 创作者中心 - 设置收款人-结果
  const sensorCreatorApplyResult = (data: SensorCreatorApplyResultType) => {
    sensors.track(SENSORS_EVENT.CREATOR_APPLY_RESULT, {
      ...data,
    });
  };

  // 内容偏好标签-弹窗弹出
  const sensorForYouTagPopShow = () => {
    sensors.track(SENSORS_EVENT.FOR_YOU_TAG_POP_SHOW);
  };
  // 内容偏好标签-弹窗关闭
  const sensorForYouTagClose = () => {
    sensors.track(SENSORS_EVENT.FOR_YOU_TAG_CLOSE);
  };
  // 内容偏好标签-确认成功提交
  const sensorForYouTagChoose = (data: SensorForYouTagChooseType) => {
    sensors.track(SENSORS_EVENT.FOR_YOU_TAG_CHOOSE, {
      ...data,
    });
  };
  // 推荐内容屏蔽原因点击
  const sensorForYouSeeFewerClick = (data: SensorForYouSeeFewerType) => {
    sensors.track(SENSORS_EVENT.FOR_YOU_SEE_FEWER_CLICK, {
      ...data,
    });
  };

  // 编辑器点击
  const sensorEditorClick = (data: SensorEditorClickType) => {
    sensors.track(SENSORS_EVENT.EDITOR_CLICK, {
      ...data,
    });
  };
  // 编辑器内弹窗打开
  const sensorEditorPopShow = (data: SensorEditorClickType) => {
    sensors.track(SENSORS_EVENT.EDITOR_POP_SHOW, {
      ...data,
    });
  };
  // 编辑器内弹窗关闭
  const sensorEditorPopClose = (data: SensorEditorClickType) => {
    sensors.track(SENSORS_EVENT.EDITOR_POP_CLOSE, {
      ...data,
    });
  };
  // 编辑器内上传文件
  const sensorEditorDocUpload = (data: SensorEditorClickType) => {
    sensors.track(SENSORS_EVENT.EDITOR_DOCU_UPLOAD, {
      ...data,
    });
  };
  // 编辑器内上传图片
  const sensorEditorPicUpload = (data: SensorEditorClickType) => {
    sensors.track(SENSORS_EVENT.EDITOR_PIC_UPLOAD, {
      ...data,
    });
  };
  // Subscription
  // 订阅/单购弹窗弹出
  const sensorsSubscriptionOrPayOriginalPopShow = (data: SensorSubscriptionOrPayOriginalPopShowType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_OR_PAY_ORIGINAL_POP_SHOW, {
      ...data,
    });
  };
  // 订阅/单购弹窗点击
  const sensorsSubscriptionOrPayOriginalClick = (data: SensorSubscriptionOrPayOriginalClickType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_OR_PAY_ORIGINAL_CLICK, {
      ...data,
    });
  };
  // 订购试用弹窗弹出
  const sensorsSubscriptionFreeTrialPopShow = (data: SensorSubscriptionFreeTrialPopShowType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_FREE_TRIAL_POP_SHOW, {
      ...data,
    });
  };
  // 订购试用弹窗弹点击
  const sensorsSubscriptionFreeTrialClick = (data: SensorSubscriptionFreeTrialClickType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_FREE_TRIAL_CLICK, {
      ...data,
    });
  };
  // 内容详情页中订阅点击
  const sensorsSubscriptionContentDetailClick = (data: SensorSubscriptionContentDetailClickType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_CONTENT_DETAIL_CLICK, {
      ...data,
    });
  };
  // 个人中心订阅点击
  const sensorsSubscriptionPersonalCenterClick = (data: SensorSubscriptionPersonalCenterClickType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_PERSONAL_CENTER_CLICK, {
      ...data,
    });
  };
  // Pricing页曝光
  const sensorsSubscriptionPricingPageView = () => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_PRICING_PAGE_VIEW);
  };
  // Pricing页点击
  const sensorsSubscriptionPricingClick = (data: SensorSubscriptionPricingClickType) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_PRICING_CLICK, {
      ...data,
    });
  };
  // 开通成功弹窗弹出
  const sensorsSubscriptionActivaSuccesslPopShow = () => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_ACTIVA_SUCCESSL_POP_SHOW);
  };
  // 开通成功弹窗点击
  const sensorsSubscriptionActivaSuccesslClick = (data: { element_name: 'to use' }) => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_ACTIVA_SUCCESSL_CLICK, {
      ...data,
    });
  };
  // 开通成功弹窗关闭
  const sensorsSubscriptionActivaSuccesslClose = () => {
    sensors.track(SENSORS_EVENT.SUBSCRIPTION_ACTIVA_SUCCESSL_CLOSE);
  };

  // Creator
  // 创作者招募落地页曝光
  const sensorsCreatorRecruitmentPageView = () => {
    sensors.track(SENSORS_EVENT.CREATOR_RECRUITMENT_PAGE_VIEW);
  };
  // 创作者招募落地页点击
  const sensorsCreatorRecruitmentClick = (data: { element_name: string }) => {
    sensors.track(SENSORS_EVENT.CREATOR_RECRUITMENT_CLICK, {
      ...data,
    });
  };

  return {
    sensors,
    sensorEditorPicUpload,
    sensorEditorDocUpload,
    sensorEditorPopClose,
    sensorEditorPopShow,
    sensorEditorClick,
    sensorBomExposure,
    sensorBomSopClick,
    sensorPrimeLimitClick,
    sensorDraftItemClick,
    sensorsLicenseAgree,
    sensorsRecommendClick,
    sensorsConfirmClick,
    sensorsReportClick,
    sensorsActivityDownload,
    sensorsShareNow,
    sensorsLottery,
    sensorsCheckFile,
    sensorsCopyCode,
    sensorsxOpenInBrowser,
    sensorsPreviewPage,
    sensorsxHollaNowClick,
    sensorsSeemoreClick,
    sensorFollowEvent,
    sensorContentClickEvent,
    sensorAddCommentEvent,
    sensorCommentFinishEvent,
    sensorCollectContentEvent,
    sensorSearchClick,
    sensorSearchResult,
    sensorSearchResultClick,
    sensorContentDetailView,
    sensorContentDetailFinish,
    sensorContentLikeEvent,
    sensorShareMyMakingClickEvent,
    sensorsNewProjectClickEvent,
    sensorsMyDraftClickEvent,
    sensorsMakingOptionClickEvent,
    sensorsSaveMakingClickEvent,
    sensorsPreviewMakingClickEvent,
    sensorsPublishMakingClickEvent,
    sensorsContentMaking,
    sensorsBannerClickEvent,
    sensorLibraryUploadFile,
    sensorsLibraryEmbedVideo,
    sensorsDragFile,
    sensorsGuideLineClickEvent,
    sensorsLibraryConfirm,
    sensorsRewardItemClick,
    sensorsShowXccDialog,
    sensorsXccDialogClickBind,
    sensorsXccDialogClickFinish,
    sensorsCloseXccDialog,
    sensorsRewardRedeemSuccess,
    sensorsShopXTool,
    sensorsEarnXToolPointsClick,
    sensorsCreateYours,
    sensorsDownloadXCS,
    sensorsHomepagecollectionClick,
    sensorsFilterClick,
    sensorsFilterFinish,
    sensorsXartusageClick,
    sensorsTryClick,
    sensorsInstallPop,
    sensorsDownpopSuccess,
    sensorsDownpopLike,
    sensorsDownpopCollect,
    sensorsDownpopShare,
    sensorsDownpopComment,
    sensorsToSupport,
    sensorsToSupportModal,
    sensorsxArtworksClick,
    sensorsfeatuedClick,
    sensorsentriesClick,
    sensorsxArtenterNowClick,
    sensorsRemixClick,
    sensorsSubmitReport,
    sensorsBillingDetail,
    sensorsStartShopping,
    sensorsPaymentConfirm,
    sensorCollectionClick,
    sensorConfigurationClick,
    sensorButtonClick,
    sensorsSeeMore,
    sensorsFeedbackClick,
    sensorsMessageCenterClick,
    sensorCommentClick,
    sensorFulterChoose,
    sensorSelectWordClick,
    sensorCreatorCenterEntryClick,
    sensorCreatorCenterMenuClick,
    sensorCreatorCenterDashboardClick,
    sensorCreatorOtherPayoutClick,
    sensorCreatorCenterSelectPayoutClick,
    sensorCreatorCenterOtherPayoutsFormSubmit,
    sensorTopicShareClick,
    sensorCreatorSetpayoutsExposure,
    sensorCreatorSetpayoutsSubmit,
    sensorCreatorApplyExposure,
    sensorCreatorApplySubmit,
    sensorCreatorApplyResult,
    sensorForYouTagPopShow,
    sensorForYouTagClose,
    sensorForYouTagChoose,
    sensorForYouSeeFewerClick,
    sensorsSubscriptionOrPayOriginalPopShow,
    sensorsSubscriptionOrPayOriginalClick,
    sensorsSubscriptionFreeTrialPopShow,
    sensorsSubscriptionFreeTrialClick,
    sensorsSubscriptionContentDetailClick,
    sensorsSubscriptionPersonalCenterClick,
    sensorsSubscriptionPricingPageView,
    sensorsSubscriptionPricingClick,
    sensorsSubscriptionActivaSuccesslPopShow,
    sensorsSubscriptionActivaSuccesslClick,
    sensorsSubscriptionActivaSuccesslClose,
    sensorsCreatorRecruitmentPageView,
    sensorsCreatorRecruitmentClick,
  };
};

<template>
  <div class="follow-button">
    <template v-if="isMyFollow">
      <df-btn
        :type="myFollowType"
        :size="size"
        :loading="isLoading"
        :disabled="disabled"
        :shape="shape"
        @click="handleFollowClick"
        @mouseenter="isHover = true"
        @mouseleave="isHover = false"
      >
        <span class="followed-text font-sans-semibold text-level-2">{{ isHover ? 'Unfollow' : 'Following' }}</span>
      </df-btn>
    </template>
    <template v-else>
      <df-btn
        :type="defaultType"
        :shape="shape"
        class="border-black flex items-center justify-center"
        :size="size"
        :loading="isLoading"
        :disabled="disabled"
        @click="handleFollowClick"
      >
        <ClientOnly v-if="showIcon">
          <icon-font type="icon-add-line" class="icon text-[24px]" />
        </ClientOnly>
        <span class="text font-sans-semibold text-level-2">Follow</span>
      </df-btn>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { doActionApi } from '@/api/user';
import { useUserInfo } from '@/stores/user';
import { OBJECT_TYPE } from '@/types/constant';
import { message } from 'ant-design-vue';
import type { SizeType } from 'ant-design-vue/lib/config-provider';
import { useSensors } from '~/composables/sensors';
type PropsType = {
  isMyFollow: boolean;
  id: number;
  contentType: string;
  name?: string;
  size?: SizeType;
  source?: string;
  shape?: 'square' | 'circle' | 'round' | 'default';
  defaultType?: 'primary' | 'special' | 'text' | 'default';
  myFollowType?: 'primary' | 'special' | 'text' | 'default';
  showIcon?: boolean;
};
type EmitsType = {
  (event: 'change', value: boolean): void;
};
const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  size: 'middle',
  name: '',
  source: undefined,
  shape: 'default',
  defaultType: 'primary',
  myFollowType: 'default',
  showIcon: true,
});
const userInfoStore = useUserInfo();
const isHover = ref(false);
const isLoading = ref(false);
const { sensorFollowEvent } = useSensors();
const disabled = computed(() => {
  return userInfoStore.myInfo.id === props.id;
});
const isLogin = computed(() => {
  return !!useMiddleCookie('utoken').value;
});
// 处理神策采集关注
const handleSensorsFollow = () => {
  sensorFollowEvent({
    follower: props.name,
    follower_community_id: props.id,
    status: props.isMyFollow ? 'unfollow' : 'follow',
    source: props.source,
  });
};
// 处理点击关注按钮
const handleFollowClick = async () => {
  if (!isLogin) {
    const { $client } = useNuxtApp();
    $client.openModal(() => {
      location.reload();
    });
  }
  try {
    isLoading.value = true;
    handleSensorsFollow();
    const { data } = await doActionApi('follow', {
      objectType: OBJECT_TYPE.USER,
      objectId: props.id,
      contentType: props.contentType,
      revoke: props.isMyFollow,
    });
    if (data.value?.code === 0) {
      message.success(`${props.isMyFollow ? 'Unfollowed' : 'Followed'} successfully`);
      emits('change', !props.isMyFollow);
    }
  } catch (error) {
    console.warn(error);
    isLoading.value = false;
  } finally {
    isLoading.value = false;
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .follow-button {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    :deep(.ant-btn) {
      display: flex;
      align-items: center;
      .anticon + span {
        margin-left: 4px;
      }
      .icon {
        font-size: 16px;
        height: 16px;
      }
    }
  }
}
</style>

<template>
  <div>
    <div v-if="!isMounted" class="mt-4 text-center">
      <a-spin :tip="'For You is loading...'" />
    </div>

    <div v-if="isMounted" class="for-you-column mt-10">
      <div
        class="m-auto px-10 pb-10"
        :style="{
          maxWidth: 'var(--maxBoxWidth)',
        }"
      >
        <div class="flex justify-between px-2">
          <span class="title font-sans-bold text-level-1">For You</span>
          <div class="flex gap-2">
            <ClientOnly>
              <df-btn size="middle" class="preferences" @click="handleClickShowPreference">
                <icon-font type="icon-Preferences1" class="text-[16px]" />
                Preferences
              </df-btn>
            </ClientOnly>
          </div>
        </div>
        <div
          v-infinite-scroll="{ infiniteLoad, keepMinHeight: true }"
          :infinite-scroll-disabled="status.finished || status.loading"
          class="relative mt-4 flex w-full flex-wrap content-start pb-10"
        >
          <template v-if="items.length > 0 || status.loading">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="card-item h-fit flex-shrink-0"
              :style="{
                width: 'var(--colBoxWidth2)',
              }"
            >
              <div class="group/block-card-wrap relative w-full p-2">
                <div
                  v-if="isShowRecommendStrategy && item.recommend?.strategy"
                  :class="[
                    `absolute left-[24px] top-[24px] z-[20]`,
                    `h-6 rounded-[30px] px-3 pt-1`,
                    `bg-text-primary font-sans-semibold text-xs text-[#FFF]`,
                    `group-hover/block-card-wrap:hidden`,
                  ]"
                >
                  {{ item.recommend?.strategy }}
                </div>
                <BlockCardItem
                  class="h-full w-full"
                  :block-id="undefined"
                  :block-title="'For You'"
                  :making-item="item"
                  :disabled-more-option="false"
                  :is-show-user-card="true"
                />
              </div>
            </div>
          </template>
          <div v-else class="absolute top-1/2 flex w-full -translate-y-1/2">
            <a-empty :image="NO_DATA" description="No data" class="mx-auto" />
          </div>
          <ClientOnly>
            <div v-if="items.length > 0 || status.loading" class="flex w-full justify-center">
              <a-spin v-if="!status.finished || status.loading" :spinning="true" />
              <a-divider v-else dashed style="color: #999">This is the bottom line</a-divider>
            </div>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import NO_DATA from '@/assets/img/no-data-red.png';
import { usePreference } from '~/stores/preference';
import { useForYou } from './hooks';

// 推荐算法调试用的
const isShowRecommendStrategy = import.meta.env.VITE_PASSPORT_ENV === 'dev';

const { items, status, init, infiniteLoad, updateMakingList } = useForYou();
const preference = usePreference();

const handleClickShowPreference = () => {
  preference.openPreferenceModal();
};

// 激活时重新获取数据
onActivated(() => {
  updateMakingList();
});

const isMounted = ref(false);
onMounted(async () => {
  await init();
  isMounted.value = true;
});
</script>

<style lang="less" scoped>
:deep(.preferences) {
  span:not(.anticon) {
    margin-inline-start: 4px;
  }
}
.assets-select {
  :deep(.ant-select-selector) {
    border-radius: 39px;
    border-color: #f0f1f5;
    background: #e9eaf0;
    box-shadow: none;
    padding-left: 16px;
    padding-right: 16px;
    .ant-select-selection-item {
      color: #575757;
    }
  }
  &:hover {
    :deep(.ant-select-selector) {
      border-color: #f0f1f5 !important;
    }
  }
  :deep(&.ant-select-focused) {
    .ant-select-selector {
      border-color: #f0f1f5 !important;
      box-shadow: none !important;
      .ant-select-selection-item {
        color: #000;
      }
    }
    .ant-select-arrow {
      color: #7a7a7a;
    }
  }
  :deep(.ant-select-arrow) {
    color: #575757;
  }
  :deep(.ant-select-clear) {
    background-color: #e9eaf0;
  }
  :deep(.ant-select-selection-placeholder) {
    color: #575757;
  }
}
</style>

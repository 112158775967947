import type { ItemType } from '@/types';
import { CONTENT_TYPE, SellTag } from '@/types';

type OptsType = {
  makingItem: ItemType;
  disabledRightExtra?: boolean;
};

// 计算数据的 computed
export const iconNumListComputed = (makingItem: ItemType) => {
  const isLiked = makingItem?.userAction?.like > 0;
  const isForsale = makingItem?.forSale;
  const list = [
    {
      icon: isLiked ? 'icon-a-30-like-2' : 'icon-a-30-like',
      isTriggled: isLiked,
      count: makingItem?.actionSum?.like || 0,
      type: 'like',
    },
  ];

  // 付费项目不展示下载量
  if (!isForsale) {
    list.push({
      icon: 'icon-a-30-Download',
      isTriggled: false,
      count: makingItem?.actionSum?.download || 0,
      type: 'download',
    });
  }

  return list;
};

export const tagMap = {
  [SellTag.BestSeller]: {
    tagName: SellTag.BestSeller,
    tagBgColor: '#FF1D40',
    tagAngleColor: '#942F19',
  },
  [SellTag.New]: {
    tagName: SellTag.New,
    tagBgColor: '#79DA3E',
    tagAngleColor: '#0E800E',
  },
  [SellTag.Exclusive]: {
    tagName: SellTag.Exclusive,
    tagBgColor: '#367AFF',
    tagAngleColor: '#23549D',
  },
  // Bundle 不会展示在前端，所以不需要配置
  [SellTag.Bundle]: {
    tagName: SellTag.Bundle,
    tagBgColor: 'linear-gradient(91deg, rgb(252, 205, 134) -6.42%, rgb(240, 165, 77) 56.02%, rgb(243, 187, 102) 101.49%)',
    tagAngleColor: '#B87514',
  },
};

// 获取售卖标签的 computed
export const tagComputed = (makingItem: ItemType, isUserCenter: boolean) => {
  const tag = makingItem.sellTag;
  const makingType = makingItem.type;
  // 个人中心
  // console.log('isUserCenter :>> ', isUserCenter);
  if (isUserCenter) {
    return {
      tagName: 'Premium',
      tagBgColor: 'linear-gradient(257deg, #FFC56F 12.38%, #EDA231 89%)',
      tagAngleColor: '#926118',
    };
  }
  // xthings + 无tag
  if (makingType === CONTENT_TYPE.XTHINGS || !tag) {
    return {
      tagName: '',
      tagBgColor: '',
      tagAngleColor: '',
    };
  }
  return tagMap[tag];
};

// 计算价格信息的 computed
export const priceComputed = (makingItem: ItemType) => {
  const discount = makingItem.discount;
  const hasDiscount = makingItem.hasDiscount;

  // 判断是否真的有折扣
  const hasDisc = makingItem.hasDiscount && discount && Object.keys(discount).length !== 0;

  // 原价
  const origPrice = +makingItem.origPrice;
  if (!hasDiscount || !discount || Object.keys(discount).length === 0) {
    return {
      hasDiscount: hasDisc,
      origPrice: `$${origPrice.toFixed(2)}`,
      disCountPrice: `$${0}`,
      savePrice: `$${0}`,
    };
  }
  // 折后价格
  const disCountPrice = discount.disPrice.toFixed(2);
  // 折扣百分比
  const discountPercent = discount.discountPercent;
  // 节约了多少
  const savePrice = (origPrice - discount.disPrice).toFixed(2);
  return {
    hasDiscount: hasDisc,
    origPrice: `$${origPrice.toFixed(2)}`,
    disCountPrice: `$${disCountPrice}`,
    discountPercent: `-${discountPercent}%`,
    savePrice: `$${savePrice}`,
  };
};

// 处理材料标签的 computed
export const materialsTagComputed = (makingItem: ItemType) => {
  const materialTag = makingItem.materialTag ?? [];
  // bundle/designs 不展示材料标签
  if ([CONTENT_TYPE.BUNDLE, CONTENT_TYPE.DESIGNS].includes(makingItem.type as CONTENT_TYPE)) {
    return '';
  }
  if (materialTag.length === 0) {
    return '';
  }
  // 只取第一个
  return materialTag[0];
};

// 处理机型tag的 computed
export const deviceTagComputed = (makingItem: ItemType) => {
  const makingType = makingItem.type;
  // xthings 不展示机型
  if (makingType === CONTENT_TYPE.XTHINGS) {
    return '';
  }

  // howtos/inspiration/designs 机型名处理
  if (makingItem.deviceName) {
    return makingItem.deviceName;
  }
  const deviceNames: string[] = [];
  if (makingItem.applyDevices?.length) {
    makingItem.applyDevices.forEach((item: string) => {
      deviceNames.push(item);
    });
  }
  return deviceNames[0];
};

export const initBlockCardMethods = (opts: OptsType) => {
  const router = useRouter();
  const { makingItem, disabledRightExtra } = opts;
  const makingType = computed(() => {
    // xthings 归为 how-to
    if (makingItem.type === CONTENT_TYPE.XTHINGS) {
      return CONTENT_TYPE.HOWTOS;
    }

    return makingItem.type;
  });

  const onTypeClick = (e: any) => {
    if (disabledRightExtra) {
      return;
    }
    e.stopPropagation();
    router.push({
      path: '/all',
      query: {
        types: makingType.value === CONTENT_TYPE.BUNDLE ? '' : makingType.value,
      },
    });
  };

  return {
    onTypeClick,
  };
};

export const useBlockCard = (opts: OptsType) => {
  return {
    ...initBlockCardMethods(opts),
  };
};

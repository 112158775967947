import { isNil } from 'lodash-es';
import type { Nilable } from '~/types/fns';

const loadDep = call(() => {
  let FileSaver: Nilable<any>;
  let JSZip: Nilable<any>;
  return async () => {
    if (isNil(FileSaver) || isNil(JSZip)) {
      await Promise.all([
        call(async () => {
          FileSaver = (await import('file-saver')).default;
        }),
        call(async () => {
          JSZip = (await import('jszip')).default;
        }),
      ]);
    }
    return { FileSaver, JSZip };
  };
});

/**
 * 获取文件
 * @param url
 */
const getBlob = (url: string, progressFn?: (percent: number) => void) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
    if (typeof progressFn === 'function') {
      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          progressFn(percentComplete); // 调用进度更新函数
        }
      };
    }
  });
};

/**
 * 批量打包zip包下载
 * @param urlArr Array [{url: 下载文件的路径, fileName: 下载文件名称}]
 * @param opts {fileName: string, successFn?: () => void} 配置项
 * @param opts.filename zip文件名
 * @param opts.success 成功回调
 */
export const downloadZip = async (
  urlArr: any,
  opts?: {
    fileName: string;
    successFn?: () => void;
    progressFn?: (percent: number) => void;
  },
) => {
  const len = urlArr.length;
  if (len === 0) {
    return;
  }

  const { FileSaver, JSZip } = await loadDep();

  // const cache = {} as any;
  const zip = new JSZip();
  const fileNames = new Set();
  let completed = 0; // 已完成下载的文件数量
  const j = 1 / len; // 计算每个文件的进度百分比

  // 并发下载远程文件，并存成 Blob 对象，同时添加一个 percent 属性
  await Promise.all(
    urlArr.map(async (item: any) => {
      let fileName = item.name;
      let counter = 1;
      while (fileNames.has(fileName)) {
        fileName = `(${counter})${item.name}`;
        counter++;
      }
      fileNames.add(fileName);
      const data = await getBlob(item.url, (percent) => {
        if (percent === 100) {
          completed++;
          // 调用进度更新函数
          if (typeof opts?.progressFn === 'function') {
            opts.progressFn(completed * j * 100);
          }
        }
      });
      // cache[fileName] = data;
      // 逐个添加文件，准备合并、压缩
      zip.file(fileName, data, { binary: true });
    }),
  );
  // 合并成一个 Blob 对象
  const content = await zip.generateAsync({ type: 'blob' });
  // 利用 file-saver 保存文件
  const fileName = opts?.fileName || 'files';
  FileSaver.saveAs(content, `${fileName}.zip`);
  // 执行成功回调
  if (typeof opts?.successFn === 'function') {
    opts.successFn();
  }
};

/** *
 * base64转文件
 */
export const base64ToFile = (base64String: string) => {
  const base64Data = atob(base64String.split(',')[1] as any);
  const binaryData = [];
  for (let i = 0; i < base64Data.length; ++i) {
    binaryData[i] = base64Data.charCodeAt(i);
  }
  const blob = new Blob([new Uint8Array(binaryData)]);
  const file = new File([blob], 'cover.png', { type: 'image/png' });
  return file;
};

/**
 * 判断是否是xcs文件
 * */

export const isXcsFile = (fileName: string) => {
  const arr = fileName.split('.');
  const length = arr.length;
  return arr[length - 1] === 'xcs';
};
export const getFileType = (fileName: string) => {
  const arr = fileName.split('.');
  const length = arr.length;
  return arr[length - 1];
};

// 获取图片格式类型
export function getFileTrueType(reader: any) {
  const bufferInt = new Uint8Array(reader.result);
  const arr = bufferInt.slice(0, 4); // 通用格式图片
  const headerArr = bufferInt.slice(0, 16); // heic格式图片
  let header = '';
  let allHeader = '';
  let realMimeType;

  for (let i = 0; i < arr.length; i++) {
    header += arr[i]?.toString(16); // 转成16进制的buffer
  }

  for (let i = 0; i < headerArr.length; i++) {
    allHeader += headerArr[i]?.toString(16);
  }
  // magic numbers: http://www.garykessler.net/library/file_sigs.html
  console.log(['header', header]);

  switch (header) {
    case '89504e47':
      realMimeType = 'image/png';
      break;
    case '47494638':
      realMimeType = 'image/gif';
      break;
    case 'ffd8ffDB':
    case 'ffd8ffdb':
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      realMimeType = 'image/jpeg';
      break;
    case '00020': // heic开头前4位可能是00020也可能是00018
    case '00018':
      allHeader.lastIndexOf('68656963') === 13 || allHeader.lastIndexOf('68656966') === 13
        ? (realMimeType = 'image/heic')
        : (realMimeType = 'unknown');
      break;
    default:
      realMimeType = 'unknown';
      break;
  }
  return realMimeType;
}

// 将未知的图片转成jpeg格式
export const formatImgType = (sourceFile: any) => {
  const originFileObj = sourceFile.originFileObj;

  return new Promise((resolve, reject) => {
    const fileReaderBuffer = new FileReader();
    const fileReader = new FileReader();
    // 读取是否是heic格式图片
    fileReaderBuffer.onload = async () => {
      const type = getFileTrueType(fileReaderBuffer);
      // 改过后缀名的heic图片 type===unknown
      console.log(type);

      if (type === 'unknown' || type.includes('/heic')) {
        const heic2any = await import('heic2any');

        heic2any
          .default({ blob: originFileObj, toType: 'image/jpeg' })
          .then((blob: any) => {
            fileReader.readAsDataURL(blob);
            const resFile = new File([blob], originFileObj.name, {
              type: 'image/jpeg',
            });
            window.URL.revokeObjectURL(blob);
            resolve({
              ...sourceFile,
              originFileObj: resFile,
              type: 'image/jpeg',
              lastModified: resFile.lastModified,
              // lastModifiedDate: resFile.lastModifiedDate,
              name: originFileObj.name,
              percent: 100,
              size: resFile.size,
              uid: sourceFile.uid,
            });
          })
          .catch(() => {
            console.log('error file');
            reject('heic2any error');
          });
      } else {
        resolve(sourceFile);
      }
      fileReader.readAsDataURL(originFileObj);
    };

    fileReaderBuffer.readAsArrayBuffer(originFileObj);
  });
};
